<template>
  <b-row>
    <b-col>
      <b-card title="Yatırımcı Bilgileri">
        <b-list-group>
          <b-list-group-item v-if="investor.company">
            <div class="font-weight-bold text-primary">
              Firma Adı
            </div>
            <div>{{ investor.company }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="investor.content">
            <div class="font-weight-bold text-primary">
              Açıklama
            </div>
            <div>{{ investor.content }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="investor.member">
            <div class="font-weight-bold text-primary">
              Kullanıcı Adı
            </div>
            <div>{{ investor.member }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="investor.email">
            <div class="font-weight-bold text-primary">
              E-Posta
            </div>
            <div>{{ investor.email }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="investor.phone">
            <div class="font-weight-bold text-primary">
              Telefon
            </div>
            <div>{{ investor.phone }}</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(formResult,index) in investor.formResult"
            :key="index"
          >
            <div class="font-weight-bold text-primary">
              {{ formResult.question }}
            </div>
            <div v-if="formResult.option">
              {{ formResult.option }}
            </div>
            <div
              v-else-if="formResult.multiple_options"
              class="mt-1"
            >
              <b-badge
                v-for="(opt,key) in formResult.multiple_options"
                :key="key"
                variant="light-success"
                class="mr-1"
              >
                {{ opt }}
              </b-badge>
            </div>
            <div v-else>
              {{ formResult.text }}
            </div>
          </b-list-group-item>
        </b-list-group>
        <div class="text-center mt-2">
          <b-button
            variant="danger"
            @click="removeData"
          >
            <FeatherIcon icon="XIcon" />
            Yatırımcı Profilini Sil
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'View',
  components: {
    BCard, BRow, BCol, BListGroup, BListGroupItem, BButton, BBadge,
  },
  computed: {
    investor() {
      return this.$store.getters['adminInvestors/dataItem']
    },
    saveStatus() {
      return this.$store.getters['adminInvestors/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status) {
        this.$router.push('/admin/investors')
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('adminInvestors/getDataItem', this.$route.params.id)
    },
    removeData() {
      this.$store.dispatch('adminInvestors/removeData', this.$route.params.id)
    },
  },
}
</script>
